import './App.css';
import { Route, Routes, BrowserRouter, HashRouter } from "react-router-dom";
import Homepage from './home.component';


function App() {
  return (
    <div >
      <HashRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          </Routes>
          </HashRouter>
      
    </div>
  );
}

export default App;
