import { useEffect, useRef, useState, useCallback } from "react"
import constants from "./constants/constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleRight, faArrowUpLong, faBars, faCaretDown, faDotCircle, faQuoteLeft, faX, faXmark } from "@fortawesome/free-solid-svg-icons"

import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import bannerImg from "../src/assets/imgs/leadgen.png"
import logo from "../src/assets/imgs/REC.COM_temp logo.png"
import headshot from "../src/assets/imgs/shutterstock_299085002-min.jpg"
import finance from "../src/assets/imgs/1.png"
import magnet from "../src/assets/imgs/2.png"
import msgs from "../src/assets/imgs/3.png"
import crm from "../src/assets/imgs/crm.jpg"
import insights from "../src/assets/imgs/insights.jpg"
import automation from "../src/assets/imgs/stock-vector-business-processes-digitization-flat-concept-vector-illustrations-set-automation-of-commercial-2356589005.jpg"
import msgs2 from "../src/assets/imgs/stock-vector-cute-man-holding-speech-bubble-or-message-notification-creative-concept-of-internet-communication-1487553494.jpg"
import megaphone from "../src/assets/imgs/stock-vector-online-advertising-campaign-flat-concept-vector-illustrations-set-seo-marketing-tools-for-business-2387382473.jpg"
import { GiCheckMark } from "react-icons/gi";
import useEmblaCarousel from 'embla-carousel-react'
// import {
//   NextButton,
//   PrevButton,
//   usePrevNextButtons
// } from './EmblaCarouselArrowButtons'
import Autoplay from 'embla-carousel-autoplay'
import { LuMessageSquareText } from "react-icons/lu";
import { HiXMark } from "react-icons/hi2";
import agent1 from '../src/assets/imgs/shutterstock_1088049662-min.jpg'
import agent2 from '../src/assets/imgs/shutterstock_567772042-min.jpg'
import agent3 from '../src/assets/imgs/Smiling_Professional-min.jpg'
import agent4 from '../src/assets/imgs/shutterstock_2423464835-min.jpg'
import { RiDoubleQuotesL } from "react-icons/ri";
import clients from "../src/assets/imgs/stock-photo-two-happy-busy-middle-aged-professionals-man-and-woman-business-leaders-partners-checking-document-2479065515.jpg"
import nar from "../src/assets/imgs/NAR_FullLockup_White.217853c2979705e25ade.png"
import remax from "../src/assets/imgs/REMAX basic white trans.png"
import rl from "../src/assets/imgs/site-logo-1533070956188.png"
import exp from "../src/assets/imgs/eXp-Realty-White-01-1024x532.webp"


export default function Homepage() {
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const [headerPosition, setHeaderPosition] = useState(500)
    const [headerTiming, setHeaderTiming] = useState(.8)
    const [popup, setPopup] = useState(false)
    const photos = useRef()
    const test = useRef()
    const details = useRef()
    const about = useRef()
    const floor = useRef()
    const contact = useRef()
    const [tab, setTab] = useState(1)
    const testimonials = [
        { name: 'Lisa Shaw', img: agent1, quote: 'I tripled my sales in six months after joining with Real Estate Clients. Well worth the investment!', title: 'Broker, Coldwell Banker' },
        { name: 'Robert McCain', img: agent2, quote: 'My favourite sales tool!', title: 'Real Estate Agent' },
        { name: 'Sarah M', img: agent3, quote: "I went from struggling with dead leads to closing 3X more deals – all thanks to RealEstateClients.com!", title: 'Top 1% Realtor' },
        { name: 'Michael T', img: agent4, quote: "I used to spend hours on follow-ups. Now I just focus on selling, and the system takes care of the rest.", title: 'Brokerage Owner' },
    ]


    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", onWindowResize);
        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);



    var lastScrollTop = 0
    var header = ''

    useEffect(() => {
        window.addEventListener("scroll", adjustHeader)
        return () => {
            window.removeEventListener("scroll", adjustHeader);
        };

    }, [])

    function adjustHeader() {
        var st = document.documentElement.scrollTop;
        var h = document.getElementById('Header')
        if (st !== 0) {
            h?.classList?.add("transparent-black");

        } else {
            h?.classList?.remove("transparent-black");

        }
        if (st > lastScrollTop) {
            h?.classList?.add("hide-header");

        } else if (st < lastScrollTop) {
            h?.classList?.remove("hide-header");


        }
        lastScrollTop = st <= 0 ? 0 : st


    }
    function navClick() {
        setHeaderPosition(500);
        setHeaderTiming(0);
        setTimeout(() => { setHeaderTiming(.8) }, 100);
        // if (typeof window != 'undefined' && window.document) {
        //     document.body.style.overflow = 'auto';
        // }
    }
    useEffect(() => {

        header = document.getElementById('Header')
    }, [])

    function isScrolledIntoView(elem) {
        if (elem) {
            var rect = elem.getBoundingClientRect();
            var elemTop = rect.top;
            var elemBottom = rect.bottom;

            var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);

            return isVisible;
        }
    }

    function isScrolledIntoViewTop(elem) {
        if (elem) {
            var rect = elem.getBoundingClientRect();
            var elemTop = rect.top;

            var isVisible = (elemTop - window.innerHeight < 0);

            return isVisible;
        }
    }

    window.addEventListener('scroll', function () {
        var features = document.getElementById('features')
        var love = document.getElementById('love')
        var sl = document.getElementById('slide')
        var slideUp = document.getElementsByClassName("slide-up");
        var slideUp2 = document.getElementsByClassName("slide-up2");
        var slide = document.getElementsByClassName("slide-in");
        var slideRight = document.getElementsByClassName("slide-right");

        if (isScrolledIntoViewTop(sl)) {
            var interval = 0;
            for (let el of slide) {
                setTimeout(() => {
                    // el.classList.remove('mt-10');
                    el?.classList.remove('slide-left');
                    el?.classList.remove('child:text-deep-grey');
                    el?.classList.remove('text-logo-first');
                    // el?.classList.add('text-deep-grey');
                    el?.classList.add('child:text-logo-first');
                    el?.classList.remove('opacity-0');
                    el?.classList.remove('min-[1300px]:opacity-0');
                    el?.classList.remove('opacity-20');


                }, interval)
                interval += 1000;
            }

        }

        for (let el of slideRight) {

            if (isScrolledIntoView(el)) {
                el?.classList.remove('slide-right');
            }

        }

        if (isScrolledIntoViewTop(features)) {
            var interval = 0;
            for (let el of slideUp) {
                setTimeout(() => {
                    // el.classList.remove('mt-10');
                    el.classList.remove('opacity-20');


                }, interval)
                interval += 500;
            }

        }

        if (isScrolledIntoViewTop(love)) {
            var interval = 0;
            for (let el of slideUp2) {
                setTimeout(() => {
                    el.classList.remove('mt-10');
                    el.classList.remove('opacity-20');


                }, interval)
                interval += 500;
            }

        }
    })

    // window.addEventListener('load', function () {
    //     var slideRight = document.getElementsByClassName("slide-right");
    //     slideRight = [...slideRight]
    //     var interval = 0;
    //     for (let el of slideRight) {
    //         setTimeout(() => {
    //             el.classList.remove('slide-right');


    //         }, interval)
    //         interval += 150;
    //     }
    // })

    async function submitForm(event) {
        event.preventDefault();
        const form = document.getElementById('form');

        const formData = new FormData(form);
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }).then((res) => res.json()).catch(err => {
            console.log(err);
        });

        if (res?.success) {
            console.log("Success", res);
            setPopup({ subject: 'Thanks for your message!', message: 'We will get back to you as soon as possible' })

        } else {
            setPopup({ subject: 'We encountered an error in sending this message', message: 'Please try again later' })
        }

    }


    // const EmblaCarousel = (props) => {
    //     const { slides, options } = props
    //     const [emblaRef, emblaApi] = useEmblaCarousel(options)
    //     const [scrollProgress, setScrollProgress] = useState(0)

    //     const {
    //       prevBtnDisabled,
    //       nextBtnDisabled,
    //       onPrevButtonClick,
    //       onNextButtonClick
    //     } = usePrevNextButtons(emblaApi)

    //     const onScroll = useCallback((emblaApi) => {
    //       const progress = Math.max(0, Math.min(1, emblaApi.scrollProgress()))
    //       setScrollProgress(progress * 100)
    //     }, [])

    //     useEffect(() => {
    //       if (!emblaApi) return

    //       onScroll(emblaApi)
    //       emblaApi
    //         .on('reInit', onScroll)
    //         .on('scroll', onScroll)
    //         .on('slideFocus', onScroll)
    //     }, [emblaApi, onScroll])

    //     return (
    //       <div className="embla">
    //         <div className="embla__viewport" ref={emblaRef}>
    //           <div className="embla__container">
    //             {slides.map((index) => (
    //               <div className="embla__slide" key={index}>
    //                 <div className="embla__slide__number">
    //                   <span>{index + 1}</span>
    //                 </div>
    //               </div>
    //             ))}
    //           </div>
    //         </div>

    //         <div className="embla__controls">
    //           {/* <div className="embla__buttons">
    //             <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
    //             <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
    //           </div> */}

    //           <div className="embla__progress">
    //             <div
    //               className="embla__progress__bar"
    //               style={{ transform: `translate3d(${scrollProgress}%,0px,0px)` }}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     )
    //   }

    function EmblaCarousel() {
        // const [emblaRef] = useEmblaCarousel({ loop: true }, [Autoplay()])
        const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false }, [Autoplay({ delay: 10000 })])
        const [scrollProgress, setScrollProgress] = useState(0)

        const onScroll = useCallback((emblaApi) => {
            const progress = Math.max(0, Math.min(1, emblaApi.scrollProgress()))
            setScrollProgress(progress * 100)
        }, [])

        useEffect(() => {
            if (!emblaApi) return

            onScroll(emblaApi)
            emblaApi
                .on('reInit', onScroll)
                .on('scroll', onScroll)
                .on('slideFocus', onScroll)
        }, [emblaApi, onScroll])

        const scrollPrev = useCallback(() => {
            if (emblaApi) emblaApi.scrollPrev()
        }, [emblaApi])

        const scrollNext = useCallback(() => {
            if (emblaApi) emblaApi.scrollNext()
        }, [emblaApi])


        return (
            <div className="embla diffuse-shadow rounded-lg xl:w-[40vw] w-[90vw]" ref={emblaRef}>
                {/* <div class="embla__viewport"> */}

                    <div className="embla__container  child:py-6 child:rounded-lg pb-16 ">
                        <div className="embla__slide p-10">
                            {/* <LuMessageSquareText className="text-[8em] pb-6 text-logo-first" /> */}
                            <img src={automation} className="w-[300px] pb-6 " />
                            <div className={`bg-light-grey w-auto border-t-gradient text-2xl rounded-lg font-normal  text-deep-grey mb-6 p-5`}>
                                Seamless Automation
                            </div>
                            <p className="pt-4 text-xl text-med-grey">Set up workflows that engage clients at every touchpoint, so
                                you're always in front of them without lifting a finger.</p>
                        </div>
                        <div className="embla__slide p-10">
                            <img src={insights} className="w-[300px] pb-6" />
                            <div className={`bg-light-grey w-auto border-t-gradient text-2xl rounded-lg font-normal  text-deep-grey mb-6 p-5`}>
                                Client Tracking &amp; Insights
                            </div>
                            <p className="pt-4 text-xl text-deep-grey">Monitor client engagement and get notified when they take
                                action, allowing you to follow up at the perfect moment.</p>
                        </div>

                        <div className="embla__slide p-10">
                            <img src={crm} className="w-[300px] pb-6" />
                            <div className={`bg-light-grey w-auto border-t-gradient text-2xl rounded-lg font-normal  text-med-grey mb-6 p-5`}>
                                Easy CRM Integration
                            </div>
                            <p className="pt-4 text-xl text-med-grey">Sync with your existing CRM.</p>
                        </div>
                        <div className="embla__slide p-10">
                            <img src={megaphone} className="w-[300px] pb-6" />
                            <div className={`bg-light-grey w-auto border-t-gradient text-2xl rounded-lg font-normal  text-med-grey mb-6 p-5`}>
                                Customizable Campaigns
                            </div>
                            <p className="pt-4 text-xl text-med-grey">Tailor messages to match your style and approach.</p>

                            <div className="ml-8 mt-3">
                                <p className="font-semibold text-logo-third"><FontAwesomeIcon icon={faDotCircle} className="-ml-5 fa-sm" /> More Personal &amp; Conversational Tone </p>
                                <p className="text-med-grey">(No cold “AI-powered” language—just real,
                                    human-focused benefits!)</p>
                            </div>
                            <div className="ml-8 mt-3">
                                <p className="font-semibold text-logo-third"><FontAwesomeIcon icon={faDotCircle} className="-ml-5 fa-sm" /> Emotionally Driven Messaging </p>
                                <p className="text-med-grey">(Speaks to what clients really care about—less stress,
                                    more deals!)</p>
                            </div>

                        {/* </div> */}

                    </div>

                    {/* <button className="embla__prev" onClick={scrollPrev}>
                        Prev
                    </button>
                    <button className="embla__next" onClick={scrollNext}>
                        Next
                    </button> */}
                </div>
                <div className="embla__progress right-10 bottom-10">
                    <div
                        className="embla__progress__bar"
                        style={{ transform: `translate3d(${scrollProgress}%,0px,0px)` }}
                    />
                </div>

            </div>
        )
    }


    return (
        <div >
            {/* {constants.PHOTOS?.map((img, index)=>
            <img className="fade-img duration-3000 absolute w-full  " key={index} src={img}/>
            )} */}
            {windowSize > 1300 ?
                <div id="Header" style={{ gridTemplateColumns: "1fr auto " }} className={`duration-1000 w-full top-0 py-4 px-8 grid min-h-28 content-center fixed z-[100]`}>
                    {/* <div className="rounded-full w-20 h-20 overflow-clip"> */}
                    <img src={logo} className="h-20" />
                    {/* </div> */}
                    <div style={{ alignItems: 'center' }} className="flex uppercase gap-x-10 text-logo-second child:duration-300 font-light text-md  child:cursor-pointer">
                        {/* <div className="group mt-2">
                            <span onClick={() => desc.current?.scrollIntoView({ behavior: 'smooth' })}>Home</span>
                            <div className="w-0 h-[3px] mt-1 bg-logo-first group-hover:w-full duration-1000" />

                        </div> */}
                        <div className="group mt-2">

                            <span onClick={() => details.current?.scrollIntoView({ behavior: 'smooth' })}>Features</span>
                            <div className="w-0 h-[3px] mt-1 bg-logo-first group-hover:w-full duration-1000" />
                        </div>

                        {/* <div className="group mt-2">

                            <span onClick={() => photos.current?.scrollIntoView({ behavior: 'smooth' })}>Pricing</span>
                            <div className="w-0 h-[3px] mt-1 bg-logo-first group-hover:w-full duration-1000" />
                        </div> */}

                        <div className="group mt-2">

                            <span onClick={() => test.current?.scrollIntoView({ behavior: 'smooth' })}>Testimonials</span>
                            <div className="w-0 h-[3px] mt-1 bg-logo-first group-hover:w-full duration-1000" />
                        </div>

                        <div className="group mt-2">

                            <span onClick={() => about.current?.scrollIntoView({ behavior: 'smooth' })}>About Us</span>
                            <div className="w-0 h-[3px] mt-1 bg-logo-first group-hover:w-full duration-1000" />

                        </div>
                        <button className="btn-primary">View Demo</button>


                    </div>

                </div> :
                <>

                    <div className=" w-full h-32 z-[60]  flex justify-between items-center text-white absolute">
                        {/* <div className="rounded-full w-24 h-24 overflow-clip z-30 mx-6 mt-4"> */}
                        <img src={constants.LOGO} className="w-24 mx-6 object-cover" />
                        {/* </div> */}
                        {/* <div className="z-20 transition-opacity duration-1000 ease-in-out w-full h-full absolute " /> */}
                        {headerPosition === 0 ?
                            <div style={{ backgroundColor: 'rgba(70, 70, 70, .3)' }} className=" z-[60] rounded-full h-10 w-10 fixed right-7 flex items-center justify-center">

                                <FontAwesomeIcon onClick={() => { setHeaderPosition(500); }} className="z-[60] text-2xl px-4 mx-4 cursor-pointer " icon={faXmark} />
                            </div>
                            :
                            <div style={{ backgroundColor: 'rgba(70, 70, 70, .3)' }} className=" z-[60] rounded-full h-10 w-10 fixed right-7 flex items-center justify-center">

                                <FontAwesomeIcon onClick={() => { setHeaderPosition(0); }} className="z-[60] text-xl px-4 mx-4 cursor-pointer " icon={faBars} />
                            </div>
                        }


                    </div>

                    <nav style={{ transition: `translate ${headerTiming}s cubic-bezier(0, .52, 0, 1)`, translate: `-${headerPosition}px`, }} className={`flex flex-col items-start border-r-4 border-med-grey text-deep-grey gap-12 px-10 py-9 text-xl bg-beige fixed top-0 pt-20 z-50 w-full max-w-[400px] h-full translate-x-[${headerPosition}vw] child:tracking-wider font-normal child:cursor-pointer`}>
                        <img className="h-28" src={logo} />
                        <div className="group">
                            <span onClick={() => { navClick(); details.current?.scrollIntoView({ behavior: 'smooth' }) }}>Features</span>
                            <div className="w-0 h-[3px] mt-2 bg-logo-first group-hover:w-full duration-1000" />
                        </div>
                        <div className="group">
                            <span onClick={() => { navClick(); test.current?.scrollIntoView({ behavior: 'smooth' }) }}>Testimonials</span>
                            <div className="w-0 h-[3px] mt-2 bg-logo-first group-hover:w-full duration-1000" />
                        </div>
                        {/* <div className="group">
                            <span onClick={() => { navClick(); video.current?.scrollIntoView({ behavior: 'smooth' }) }}>Testimonials</span>
                            <div className="w-0 h-[3px] mt-2 bg-logo-first group-hover:w-full duration-1000" />
                        </div> */}

                        <div className="group">
                            <span onClick={() => { navClick(); about.current?.scrollIntoView({ behavior: 'smooth' }) }}>About Us</span>
                            <div className="w-0 h-[3px] mt-2 bg-logo-first group-hover:w-full duration-1000" />
                        </div>
                        <div className="group">
                            <a href="https://link.realestateclients.com/widget/bookings/recdemo" target="_blank">Book A Demo</a>
                            <div className="w-0 h-[3px] mt-2 bg-logo-first group-hover:w-full duration-1000" />
                        </div>
                    </nav>

                </>
            }
            <div className="w-full max-w-[2000px] mx-auto h-screen relative">
                <img src={bannerImg} className="absolute md:w-[50%] w-full md:right-20 bottom-12" />
                <div className="md:px-20 px-10 md:py-48 py-24 min-[1300px]:max-w-[60%] relative z-1">

                    <h1 style={{ lineHeight: 1.25 }} className="md:text-6xl text-4xl text-logo-third mont font-semibold pb-10">Nurture Your <span className="font-extrabold italic">Leads</span> And Convert them to <span className="font-extrabold italic">Deals</span> – Without Lifting a Finger!</h1>
                    <p className="md:text-lg font-medium pb-12 text-med-grey max-w-[75%]">We take care of your lead nurturing so you can focus on closing deals – not chasing
                        dead-end leads.</p>
                    <button className="btn-primary">Start Your Free Trial</button>
                </div>
            </div>
            <div className="pt-20 lg:h-[80vh] h-screen">
                <div className="absolute right-0 w-[70%] bg-light-grey h-[60%] pt-20 -z-10"></div>
                <div className="flex flex-col-reverse gap-y-10 lg:flex-row gap-x-12 justify-between px-[10%] mt-10">
                    <div>
                        <div className="rounded-full h-52 w-52 overflow-clip mb-12">
                            <img src={headshot} className="w-full h-full object-cover" />
                        </div>
                        <p className="italic font-normal text-2xl text-med-grey max-w-[600px] pb-4">"This platform tripled my lead conversion rate in just 3 months!"</p>
                        <p className="text-xl text-deep-grey"> – Top Agent, NYC</p>
                    </div>
                    <div className="text-right md:pt-16">

                        <h2 className="mont md:text-5xl text-4xl text-med-grey font-normal pb-10 slide-right duration-1000">Proven Success & Happy Clients</h2>
                        <p className="text-2xl text-deep-grey slide-right duration-1500">Trusted by <span className="font-normal text-logo-first text-6xl">10k+</span> Real Estate Professionals</p>
                    </div>

                </div>

            </div>

            <div className="pb-20">
                <h2 className="mont text-4xl md:text-5xl text-deep-grey font-normal pb-10 text-center ">How It Works</h2>
                <div id="features" className="mx-auto max-w-[1200px] flex flex-col gap-y-24 px-14">
                    <div style={{ gridTemplateColumns: windowSize > 1000 ? '60% 40%' : '100%' }} className="grid gap-x-24 gap-y-10 opacity-20 slide-up duration-1000">
                        <div className="border-l-[3px] border-l-logo-first pl-10 relative min-h-[300px]">
                            <p className="vertical text-logo-first  uppercase">Capture</p>
                            <p className="text-5xl  text-logo-first">01</p>
                            <div className="absolute bottom-0">
                                <p className="text-3xl text-deep-grey pb-6">Capture Leads Effortlessly</p>
                                <p className="text-med-grey text-xl">Sync with your CRM, website, or MLS—leads flow in
                                    automatically.</p>
                            </div>

                        </div>
                        <img src={magnet} className="w-[300px] opacity-20 slide-up duration-1000 " />

                    </div>
                    <div style={{ gridTemplateColumns: windowSize > 1000 ?  '40% 60%' : '100%' }} className="grid gap-x-24 gap-y-10 opacity-20 slide-up duration-1000 ">
                        <img src={msgs} className="w-[300px] opacity-20 slide-up duration-1000 max-[1000px]:order-2" />
                        <div className="border-l-[3px] border-l-logo-first pl-10 relative min-h-[300px] max-[1000px]:order-1">
                            <p className="vertical text-logo-first  uppercase">Engage</p>

                            <p className="text-5xl  text-logo-first">02</p>
                            <div className="absolute bottom-0">
                                <p className="text-3xl text-deep-grey pb-6">Engage Instantly</p>
                                <p className="text-med-grey text-xl">Our automated messages make sure every lead feels valued and engaged.</p>
                            </div>

                        </div>


                    </div>
                    <div style={{ gridTemplateColumns: windowSize > 1000 ? '60% 40%' : '100%' }} className="grid gap-x-24 gap-y-10 opacity-20 slide-up duration-1000 ">
                        <div className="border-l-[3px] border-l-logo-first pl-10 relative min-h-[300px]">
                            <p className="vertical text-logo-first  uppercase">Convert</p>
                            <p className="text-5xl  text-logo-first">03</p>
                            <div className="absolute bottom-0">
                                <p className="text-3xl text-deep-grey pb-6 ">Convert More Clients, Stress-Free</p>
                                <p className="text-med-grey text-xl">Follow-ups happen while you focus on what you do
                                    best—closing deals.</p>
                            </div>

                        </div>
                        <img src={finance} className="w-[300px]  opacity-20 slide-up duration-1000 " />

                    </div>
                </div>

            </div>

            <div ref={details} className="pb-20 min-h-[700px] bg-light-grey px-10">
                <h2 className="mont text-4xl md:text-5xl text-deep-grey font-normal  text-center pb-8 pt-20">Why Real Estate Clients?</h2>
                <h2 className="mont text-2xl text-med-grey font-normal italic text-center pb-16">Time is money in real estate, and nurturing relationships shouldn’t slow you down.
                    RealEstateClients.com helps you automate your client communications, so you never miss an
                    opportunity to engage. Get more referrals, close more deals, and reduce your workload.</h2>

                <div id="love" className="grid min-[1350px]:grid-cols-4 min-[700px]:grid-cols-2 child:max-w-[400px] gap-x-16 gap-y-20 justify-center sm:justify-between mx-auto max-w-[1500px] pt-10  ">

                    <div className="relative opacity-20 slide-up2 duration-1000 mt-10 ">
                        <GiCheckMark className="absolute opacity-15 text-logo-first min-[1350px]:text-[15em] text-[10em] translate-x-[-50%] left-[50%]" />
                        <h2 className="text-2xl font-normal text-deep-grey pb-6">Never Miss a Referral Again</h2>
                        <p className="font-semibold text-med-grey">Follow-ups happen automatically, even while you’re busy.</p>
                    </div>


                    <div className="relative opacity-20 slide-up2 duration-1000 mt-10 ">
                        <GiCheckMark className="absolute opacity-15 text-logo-first min-[1350px]:text-[15em] text-[10em] translate-x-[-50%] left-[50%]" />
                        <h2 className="text-2xl font-normal text-deep-grey pb-6">Personalized Client Communication</h2>
                        <p className="font-semibold text-med-grey">Messages feel natural and engaging, not robotic.</p>
                    </div>

                    <div className="relative opacity-20 slide-up2 duration-1000 mt-10 ">
                        <GiCheckMark className="absolute opacity-15 text-logo-first min-[1350px]:text-[15em] text-[10em] translate-x-[-50%] left-[50%]" />
                        <h2 className="text-2xl font-normal text-deep-grey pb-6">Increase Referral &amp; Clients</h2>
                        <p className="font-semibold text-med-grey">Stay memorable without being intrusive.</p>
                    </div>

                    <div className="relative opacity-20 slide-up2 duration-1000 mt-10 ">
                        <GiCheckMark className="absolute opacity-15 text-logo-first min-[1350px]:text-[15em] text-[10em] translate-x-[-50%] left-[50%]" />
                        <h2 className="text-2xl font-normal text-deep-grey pb-6">Effortless Automation</h2>
                        <p className="font-semibold text-med-grey">Set it up once, and let it handle outreach while you focus on closing
                            deals.</p>
                    </div>


                </div>



            </div>

            <div className="main-block flex flex-wrap gap-16 justify-between overflow-clip">
                <div className="flex flex-col gap-y-7 mt-6">

                    <h2 id="slide" className="mont min-[700px]:text-[2.5rem] text-[2rem] opacity-20 text-logo-first min-[700px]:whitespace-nowrap font-normal slide-in slide-left duration-1500 ">More Than a CRM.</h2>
                    <h2 className="mont min-[700px]:text-[2.5rem] text-[2rem] opacity-20 text-logo-first min-[700px]:whitespace-nowrap font-normal slide-in slide-left duration-1500 ">More Than a Marketing Tool. </h2>
                    <h2 className="mont min-[700px]:text-[2.5rem] text-[2rem] opacity-20 text-logo-first child:text-deep-grey min-[700px]:whitespace-nowrap font-normal slide-in slide-left duration-1500 ">A Real Estate <span className="font-semibold italic duration-1500 ">Growth</span> Machine.</h2>
                    <a href="https://link.realestateclients.com/widget/bookings/recdemo" target="_blank" className="btn-primary self-start min-[1300px]:opacity-0 duration-3000 slide-in mt-8">Book A Live Demo</a>
                </div>
                <div className="">
                    {/* <div className="flex gap-x-2 ">
                        <div className={`p-4 ${tab === 1 ? 'bg-logo-second border-t-4 border-logo-first text-white' : 'bg-light-accent'}`}>
                            Smart Follow-Ups, Done for You
                        </div>
                        <div className={`p-4 ${tab === 2 ? 'bg-logo-second border-t-4 border-logo-first text-white' : 'bg-light-accent'}`}>
                            Never Lose a Lead
                        </div>
                    </div> */}


                    <EmblaCarousel  >

                    </EmblaCarousel>




                </div>
            </div>
            <div className="main-block">


                <h1 className="mont text-4xl md:text-5xl text-deep-grey font-normal  text-center pb-8 ">Affordable Pricing for Every Agent &amp; Team</h1>
                <h2 className="mont text-2xl text-med-grey font-normal italic text-center pb-16">Scale As You Grow</h2>

                <div className="grid lg:grid-cols-3 gap-x-10 gap-y-14 justify-center">
                    <div className="p-10 rounded-lg diffuse-shadow flex flex-col gap-4 purple-gradient">
                        <p className="font-semibold text-3xl text-logo-third self-center">Starter</p>
                        <p className="font-normal text-2xl text-deep-grey self-center">$20/month</p>
                        <p className="italic font-normal text-med-grey pb-4">Perfect for solo agents just getting started.</p>
                        <hr className="pb-4 text-[#c9c9c9]" />
                        <div className="flex gap-2 items-center"><GiCheckMark />Some feature</div>
                        <div className="flex gap-2 items-center"><GiCheckMark />Some feature</div>
                        <div className="flex gap-2 items-center"><HiXMark />Other feature</div>
                        <div className="flex gap-2 items-center"><HiXMark />Other feature</div>
                        <div className="flex gap-2 items-center"><HiXMark />Other feature</div>
                    </div>
                    <div className="p-10 rounded-lg diffuse-shadow flex flex-col gap-4 green-gradient">
                        <p className="font-semibold text-3xl text-logo-first self-center">Pro</p>
                        <p className="font-normal text-2xl text-deep-grey self-center">$30/month</p>
                        <p className="italic font-normal text-med-grey pb-4">Advanced automation &amp; analytics for growing businesses.</p>
                        <hr className="pb-4 text-[#c9c9c9]" />
                        <div className="flex gap-2 items-center"><GiCheckMark />Some feature</div>
                        <div className="flex gap-2 items-center"><GiCheckMark />Some feature</div>
                        <div className="flex gap-2 items-center"><HiXMark />Other feature</div>
                        <div className="flex gap-2 items-center"><HiXMark />Other feature</div>
                        <div className="flex gap-2 items-center"><HiXMark />Other feature</div>
                    </div>
                    <div className="p-10 rounded-lg diffuse-shadow flex flex-col gap-4 orange-gradient">
                        <p className="font-semibold text-3xl text-orange self-center">Enterprise</p>
                        <button className="btn-secondary self-center my-3">
                            Request a Quote
                        </button>
                        <p className="italic font-normal text-med-grey pb-4">White-label &amp; team solutions.</p>
                        <hr className="pb-4 text-[#c9c9c9]" />
                        <div className="flex gap-2 items-center"><GiCheckMark />Some feature</div>
                        <div className="flex gap-2 items-center"><GiCheckMark />Some feature</div>
                        <div className="flex gap-2 items-center"><HiXMark />Other feature</div>
                        <div className="flex gap-2 items-center"><HiXMark />Other feature</div>
                        <div className="flex gap-2 items-center"><HiXMark />Other feature</div>
                    </div>

                </div>
            </div>
            <div ref={test} className="bg-light-grey">
                <h1 className="mont text-4xl md:text-5xl text-deep-grey font-normal  text-center pb-6 pt-20 ">What Our Clients Are Saying</h1>
                <h2 className="mont text-2xl text-med-grey font-normal italic text-center pb-6">Real Results from Real Agents</h2>



                <div className="grid main-block min-[900px]:grid-cols-2 gap-16 min-h-[35vh] justify-center min-[1050px]:justify-normal">
                    {testimonials.map((agent) =>
                        <div className="duration-700 relative w-full-h-full diffuse-shadow bg-white text-deep-grey  group rounded-xl ">

                            <div className="flex flex-col p-6 gap-4 w-full pb-8 relative">
                                <div className="flex justify-between items-center">
                                    < RiDoubleQuotesL className="text-7xl text-logo-second" />
                                    <div className="h-36 w-36 rounded-bl-full overflow-hidden z-10  ">

                                        <img style={{ objectPosition: '20% 0' }} className="w-full h-full object-cover " src={agent.img} />
                                    </div>
                                </div>

                                {/* <div className="px-4 py-2 duration-1000 h-[100px] bg-logo-second bg-opacity-50 absolute bottom-0 w-full text-white group-hover:h-full"> */}
                                <p className="text-lg font-normal text-med-grey italic pt-6">{agent.quote}</p>
                                <p className="text-xl text-deep-grey mont font-semibold pt-3">{agent.name}</p>
                                <p className="text-md text-deep-grey -mt-3 ">{agent.title}</p>


                            </div>
                            {/* <p className="mt-7 text-xl font-semibold">About {agent.name?.split(' ')[0]}</p>
                                <p className="mt-3">{agent.bio ? agent.bio.replace(/(<([^>]+)>)/gi, '')?.split(' ')?.splice(0, 50)?.join(' ') + " ..." : 'Coming Soon'}</p> */}
                            {/* </div> */}
                        </div>


                    )}
                </div>
            </div>
            <div ref={about} className="main-block">

                <h1 className="mont text-4xl md:text-5xl text-deep-grey font-normal  text-center pb-6  pt-2">About Us</h1>
                <h2 className="mont text-2xl text-med-grey font-normal italic text-center pb-6"><span className="font-semibold">RealEstateClients.com:</span> Transforming Client Relationships into Lifelong Success</h2>
                <div className="flex flex-col md:flex-row gap-16 pt-16">
                    <img src={clients} className="md:h-[30vw] w-[90vw] md:w-auto md:max-h-[350px] self-start rounded-br-full" />
                    <p style={{ lineHeight: '1.75em' }} className="text-med-grey font-normal ">With over 15 years in the real estate industry, the founder of RealEstateClients.com brings
                        unparalleled expertise in client retention and business growth. Leveraging deep industry
                        knowledge and a vast professional network, we provide strategies that drive long-term success
                        for realtors.<br /><br />
                        Unlike traditional CRM tools, RealEstateClients.com goes beyond simple lead management. Our
                        platform turns your database into a continuous source of business by strengthening client
                        relationships and maximizing profitability.</p>
                </div>
                <div className="grid sm:grid-cols-2 gap-16 pt-16">
                    <div>
                        <h1 className="mont text-2xl text-deep-grey font-semibold pb-6 ">Our Mission</h1>


                        <p style={{ lineHeight: '1.75em' }} className="text-med-grey font-normal ">
                            We believe real estate success isn’t just about generating leads—it’s about nurturing them.
                            That’s why we’ve built a solution that automates the hard work, ensuring you stay connected
                            with clients, close more deals, and grow your business effortlessly.</p>
                    </div>
                    <div>
                        <h1 className="mont text-2xl text-deep-grey font-semibold pb-6 ">Our Vision</h1>


                        <p style={{ lineHeight: '1.75em' }} className="text-med-grey font-normal ">
                            To be the ultimate partner for real estate professionals, helping them stay organized, engaged,
                            and ahead of the competition—from the first interaction to years beyond the sale.</p>
                    </div>
                </div>
            </div>
            <div className="bg-deep-grey p-10 ">
                <h1 className="mont text-2xl text-white font-normal pb-10 ">In Association With:</h1>

                <div className="flex flex-wrap gap-16">

                    <img src={nar} className="h-10" />
                    <img src={remax} className="h-7" />
                    <img src={rl} className="h-10" />
                    <img src={exp} className="h-10" />
                </div>

            </div>





            {/* <div ref={contact} className=" bg-beige">
                <div className="main-block text-black">
                    <div className="border-l-4 border-t-4 pt-4 pl-5 pr-8  w-auto inline-block mb-10">

                        <span className="text-5xl inter font-bold">Presented By</span>
                    </div>
                    <div className="flex flex-col lg:flex-row gap-20 py-8">
                        <div className="w-[300px] h-[300px] rounded-full overflow-clip">

                            <img className="object-cover w-full h-full object-center" src={constants.HEADSHOT} />
                        </div>
                        <div>
                            <h1 className="font-semibold inter text-3xl pb-10">{constants.AGENT_NAME}</h1>

                            <div className="flex flex-wrap gap-x-12 gap-y-8">
                                <div>
                                    <p className="uppercase font-semibold pb-3">Brokerage</p>
                                    <p className="font-normal">{constants.BROKERAGE}</p>
                                </div>
                                <div>
                                    <p className="uppercase font-semibold pb-3">Title</p>
                                    <p className="font-normal">{constants.AGENT_TITLE}</p>
                                </div>
                                <div>
                                    <p className="uppercase font-semibold pb-3">Phone</p>
                                    <p className="font-normal">{constants.PHONE}</p>
                                </div>
                                <div>
                                    <p className="uppercase font-semibold pb-3">Email</p>
                                    <p className="font-normal">{constants.EMAIL}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4 className="font-light  text-2xl min-w-[20vw] pt-6 uppercase">Get In Touch</h4>
                        <form id="form" onSubmit={submitForm} className="mt-6 flex flex-col max-w-[700px]">
                            <input type="hidden" name="access_key" value={constants.EMAIL_ACCESS_KEY} />
                            <input type="hidden" name="subject" value="New Contact Form Message" />
                            <div className="form-item">
                                <label className="form-label">Name*</label>
                                <input className="form-input" name="Name" placeholder="Full Name"></input>
                            </div>
                            <div className="form-item">
                                <label className="form-label">Email*</label>
                                <input className="form-input" name="Email Address" placeholder="Email Address"></input>
                            </div>
                            <div className="form-item">
                                <label className="form-label">Message*</label>
                                <textarea className="form-input resize-none h-32" name="Message" placeholder="Your Message"></textarea>
                            </div>
                            <button type="submit" className="btn-tertiary self-start  px-10">Send</button>
                        </form>
                    </div>
                </div>
            </div> */}



            {popup === 'open' &&
                <>
                    <div className="backdrop"></div>
                    <div className="popup">
                        <p className="text-xl font-normal uppercase text-black">Upcoming Open Houses</p>
                        {constants.OPEN_HOUSE.map((open) =>
                            <div className="px-6 py-4 border border-black bg-beige">
                                <p>{open.start.toLocaleString('default', { dateStyle: 'long' })}</p>
                                <p>{open.start.toLocaleString('default', { timeStyle: 'short' })} to {open.end.toLocaleString('default', { timeStyle: 'short' })}</p>


                            </div>
                        )}
                        <button onClick={() => setPopup(false)} className="btn-tertiary">Close</button>
                    </div>
                </>
            }

        </div>
    )
}